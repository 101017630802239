import { Card } from 'antd';
import { Subscribe } from '@models/subscribe';
import styled from 'styled-components';

interface SubscribeProps {
  data?: Subscribe;
}

const SubscribeCard = (props: SubscribeProps) => {
  return (
    <Card style={{ width: 300 }}>
      <SubscribeName>{props.data?.name} 님</SubscribeName>
      <SubscribeCarNumber>{props.data?.carNumber}</SubscribeCarNumber>
      <SubscribeVisitCount>{props.data?.visitCount}</SubscribeVisitCount>
    </Card>
  );
};

export { SubscribeCard };

const SubscribeName = styled.h1`
  font-size: 21px;
  line-height: 28px;
  font-weight: 700;
  padding: 0px 16px;
  white-space: pre-wrap;
`;

const SubscribeCarNumber = styled.p`
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
  padding: 0px 16px;
  white-space: pre-wrap;
`;

const SubscribeVisitCount = styled.p`
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
  padding: 0px 16px;
  white-space: pre-wrap;
`;
