import { useEffect, useState } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { WEBSOCKET_SERVER_HOST } from '@src/config';
import { Subscribe } from '@models/subscribe';

export enum SocketState {
	CONNECTING = ReadyState.CONNECTING,
	OPEN = ReadyState.OPEN,
	CLOSING = ReadyState.CLOSING,
	CLOSED = ReadyState.CLOSED,
	UNINSTANTIATED = ReadyState.UNINSTANTIATED,
}

export const useSocket = () => {
	const [responseMessage, setResponseMessage] = useState<Subscribe>();

	const { lastJsonMessage, readyState } = useWebSocket<Subscribe>(WEBSOCKET_SERVER_HOST);

	const connectionStatus = {
		[ReadyState.CONNECTING]: 0,
		[ReadyState.OPEN]: 1,
		[ReadyState.CLOSING]: 2,
		[ReadyState.CLOSED]: 3,
		[ReadyState.UNINSTANTIATED]: -1,
	}[readyState];

	useEffect(() => {
		if (lastJsonMessage !== null) {
			setResponseMessage(lastJsonMessage);
		}
	}, [lastJsonMessage]);

	return { responseMessage: responseMessage, connectionStatus: connectionStatus };
};
