import { Layout } from 'antd';
import { useSocket, SocketState } from '../../hooks/useSocket';
import { useEffect } from 'react';
import { SubscribeCard } from '@components/SubscribeCard';
const { Content } = Layout;

export default function Connect() {
  const { responseMessage, connectionStatus } = useSocket();

  if (connectionStatus === SocketState.CONNECTING) console.log('Socket Connecting');
  if (connectionStatus === SocketState.OPEN) console.log('Socket Open');
  if (connectionStatus === SocketState.CLOSED) console.log('Socket Closed');

  useEffect(() => {}, [responseMessage]);

  return (
    <Layout>
      <Content>
        <SubscribeCard data={responseMessage}></SubscribeCard>
      </Content>
    </Layout>
  );
}
