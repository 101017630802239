import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { appSettingApi } from '@src/apis';
import { useEffect } from 'react';
import styled from 'styled-components';

export default function Main() {
  const navigate = useNavigate();
  const handleClick = () => navigate('/connect');
  const getAppSettings = async () => {
    try {
      const { data } = await appSettingApi.getAppSettings();

      return data;
    } catch (e) {
      navigate('/login');
    }
  };

  useEffect(() => {
    getAppSettings().then((res) => console.log(res));
  }, []);

  return (
    <ContainerStyle>
      <ConnectContainerStyle>
        <TitleStyle>차량인식기 연결</TitleStyle>
        <ConnectButtonStyle onClick={handleClick}> 연결하기 </ConnectButtonStyle>
      </ConnectContainerStyle>
    </ContainerStyle>
  );
}

const ContainerStyle = styled.div`
  position: relative;
  width: 100vw;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ConnectContainerStyle = styled.div`
  position: relative;
  width: 15vw;
  height: 20vh;
  padding: 30px;
`;

const TitleStyle = styled.h1`
  text-align: center;
`;

const ConnectButtonStyle = styled(Button)`
  margin: 0;
  margin-bottom: 16px;
  width: 100%;
  height: 100%;
  background-color: #e10600;
  color: #fff;
  font-size: 32px;

  &:hover {
    color: #f02c2c !important;
    border: 1px solid crimson !important;
  }
`;
