import { AdminControllerApi, AppSettingControllerApi, Configuration } from '@kingcar-club/typescript-interface';
import { API_HOST } from '@src/config';
import { getLocalStorage, setLocalStorage } from '@src/utils/localStorage';
import axios from 'axios';
import * as jose from 'jose';

const configuration = new Configuration({ basePath: `https://${API_HOST}` });
const axiosInstance = axios.create();
const axiosInstanceWithAuth = axios.create();
axiosInstanceWithAuth.interceptors.request.use(async (request) => {
  try {
    const accessToken = getLocalStorage('accessToken');
    const refreshToken = getLocalStorage('refreshToken');

    if (!accessToken) throw new Error('no accessToken');

    const { exp } = jose.decodeJwt(accessToken);
    if (!exp) throw new Error('no exp');

    const now = Math.floor(Date.now() / 1000);

    if (exp > now) {
      request.headers.Authorization = `Bearer ${accessToken}`;
    } else {
      if (!refreshToken) throw new Error('no refreshToken');

      const { data } = await adminApi.adminTokenReissue({
        accessToken,
        refreshToken,
      });

      if (!data.success) throw new Error('reissue failed');

      setLocalStorage('accessToken', data.result?.accessToken || '');
      setLocalStorage('refreshToken', data.result?.refreshToken || '');
      request.headers.Authorization = `Bearer ${data.result?.accessToken}`;
    }

    return request;
  } catch (e) {
    console.log('test', e);
    window.location.href = '/login';

    return request;
  }
});
axiosInstanceWithAuth.interceptors.response.use(
  (response) => response,
  (error) => {
    if (axios.isAxiosError(error)) {
      console.log(error);
      if (error.response?.status === 401) {
        console.log('error!');
        window.location.href = '/login';
      }
      return error;
    } else {
      return error;
    }
  },
);

export const adminApi = new AdminControllerApi(configuration, undefined, axiosInstance);
export const appSettingApi = new AppSettingControllerApi(configuration, undefined, axiosInstanceWithAuth);
