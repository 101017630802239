import { useNavigate } from 'react-router-dom';
import { Button, Form, Input, message } from 'antd';
import { adminApi } from '@src/apis';
import { setLocalStorage } from '@src/utils/localStorage';
import styled from 'styled-components';
import BI from '@src/assets/bi.svg';
import { LoginDto } from '@kingcar-club/typescript-interface';

export default function Login() {
  const navigate = useNavigate();

  const handleSubmit = async (values: LoginDto) => {
    try {
      const { email, password } = values;

      const { data } = await adminApi.adminLogin({
        email,
        password,
      });

      if (data.success) {
        message.success('로그인 성공');
        setLocalStorage('accessToken', data.result?.accessToken || '');
        setLocalStorage('refreshToken', data.result?.refreshToken || '');

        navigate('/');
      }
    } catch (e) {
      console.error(e);
      message.error('로그인에 실패했습니다. 다시 시도해주세요.');
    }
  };

  return (
    <ContainerStyle>
      <LoginFormContainerStyle onFinish={handleSubmit}>
        <LogoContainerStyle>
          <LogoStyle src={BI} />
        </LogoContainerStyle>
        <TitleStyle>킹카클럽 관리자 로그인</TitleStyle>
        <SubTitleStyle>차량 인식기 웹</SubTitleStyle>
        <FormItemStyle name='email' label='이메일' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} rules={[{ required: true, message: '이메일을 입력해주세요.' }]}>
          <Input placeholder='이메일을 입력해주세요' />
        </FormItemStyle>
        <FormItemStyle name='password' label='비밀번호' labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} rules={[{ required: true, message: '비밀번호를 입력해주세요.' }]}>
          <Input type='password' placeholder='비밀번호를 입력해주세요' />
        </FormItemStyle>
        <Form.Item>
          <SubmitButtonStyle htmlType='submit'>로그인</SubmitButtonStyle>
        </Form.Item>
      </LoginFormContainerStyle>
    </ContainerStyle>
  );
}

const ContainerStyle = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LoginFormContainerStyle = styled(Form)`
  width: 360px;
  padding: 30px;
`;

const FormItemStyle = styled(Form.Item)`
  margin: 0;
  margin-bottom: 16px;
`;
const SubmitButtonStyle = styled(Button)`
  width: 100%;
  height: 40px;
  background-color: #e10600;
  color: #fff;

  &:hover {
    color: #000 !important;
    border: 1px solid crimson !important;
  }
`;

const LogoContainerStyle = styled.p`
  position: relative;
  width: 80px;
  height: 80px;
  margin: 0 auto;
`;

const LogoStyle = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const TitleStyle = styled.h1`
  text-align: center;
`;

const SubTitleStyle = styled.h3`
  text-align: center;
`;
