type Env = 'production' | 'development';

export const APP_ENV: Env = (() => {
  if (import.meta.env.VITE_APP_ENV === 'production' || import.meta.env.VITE_APP_ENV === 'development') return import.meta.env.VITE_APP_ENV;
  if (import.meta.env.MODE === 'production' || import.meta.env.MODE === 'development') return import.meta.env.MODE;
  return 'development';
})();

export const WEBSOCKET_SERVER_HOST = {
  production: 'wss://api.kingcar.club/websocket',
  development: 'wss://api-test.kingcar.club/websocket',
}[APP_ENV];

export const API_HOST = {
  production: 'api.kingcar.club',
  development: 'api-test.kingcar.club',
}[APP_ENV];
