import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Main from './pages/Main';
import Connect from './pages/Connect';
import Login from './pages/Login';

const router = createBrowserRouter([
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/',
    element: <Main />,
  },
  {
    path: '/connect',
    element: <Connect />,
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
